import React, { useState } from "react";

// IMPORTS FOR WELCOME PAGE

//import css
import "../../styles/indexPageStyles/indexPageStyles.css";
import { styled } from "@mui/material/styles";

//import
import { ButtonBase, Typography } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Paper from "@mui/material/Paper";

//icon
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { East } from "@mui/icons-material";
import OurServicesHook from "../../hooks/OurServicesHook";

import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import EastIcon from "@mui/icons-material/East";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";

import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

//checkbox
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//countUp
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

// import for dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

// HOOKS IMPORT
import ClientsHooks from "../../hooks/ClientsHooks";
import IndexPageHook from "../../hooks/indexPageHook/IndexPageHook";

// For slide
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// For Textfield
const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#f1f8fb !important",
  },
  "& .Mui-focused": {
    backgroundColor: "#f1f8fb !important",
  },
  ".MuiFilledInput-multiline": {
    backgroundColor: "#f1f8fb !important",
  },
  "& .onhover": {
    backgroundColor: "#f1f8fb !important",
  },
});

const IndexPage = () => {
  // ==== for welcome page
  const { ...param } = IndexPageHook();
  const [counterOn, setCounterOn] = useState(false);

  //==== for dialog
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // //   ==== for clients hooks
  // const { ...param2 } = ClientsHooks();

  return (
    <div>
      {/* ====================== WELCOME + OUR SERVICES + STATS ========================*/}
      <div>
        <div id="home" class="welcomeBG">
          <div
            style={{
              textAlign: "center",
              // paddingTop: "90px",
              paddingTop: "210px",
              position: "static",
              top: 0,
            }}
          >
            <Typography
              color="common.white"
              // fontSize="20%"
              // fontSize="1vw"
              // fontSize="2.3vh"
            >
              IN THIS JOURNEY
            </Typography>
            <Typography
              md={12}
              fontFamily="Barlow Condensed"
              // lineHeight="90px"
              lineHeight="125%"
              fontSize="11vh"
              // fontSize="5vw"
              fontWeight={600}
              color="common.white"
              sx={{
                pt: "15px",
                "@media (max-width: 900px)": {
                  // color: "black",
                  fontSize: "7vw",
                },
              }}
            >
              {/* Best IT Solution & Service <br></br> In Business World */}
              We Can Create <br></br>Something Better Together
            </Typography>
            <Typography
              color="common.white"
              fontSize="2.6vh"
              sx={[
                {
                  pt: "20px",
                  "@media (max-width: 600px)": {
                    // color: "black",
                    // fontSize: "2.3vw",
                    fontSize: "14px",
                  },
                },
              ]}
            >
              Begin your collaboration with Servmore today! Explore the services
              we offer below and contact us to start your journey towards
              greater success.
            </Typography>
          </div>

          <div
            style={{
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "179px",
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={4} />
              <Grid item md={2}>
                <Button
                  sx={{
                    color: "primary.main",
                    backgroundColor: "common.white",
                    "&:hover": {
                      color: "common.white",
                    },
                  }}
                  variant="contained"
                  endIcon={<East />}
                  disableElevation
                >
                  <Typography
                    sx={{
                      margin: "5px",
                      fontSize: "15px",
                    }}
                    onClick={(e) => {
                      e.preventDefault();
                      window.location.replace("/#services");
                    }}
                  >
                    Learn More
                  </Typography>
                </Button>
              </Grid>
              <Grid item md={2}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ButtonBase
                    TouchRippleProps
                    sx={{
                      borderRadius: "100%",
                    }}
                  >
                    <PlayCircleIcon
                      sx={{
                        fontSize: "45px",
                        color: "common.white",
                        "&:hover": {
                          cursor: "pointer",
                          color: "primary.main",
                        },
                      }}
                    />
                  </ButtonBase>

                  <Typography
                    sx={{
                      margin: "5px",
                      fontSize: "17px",
                      color: "common.white",
                    }}
                  >
                    Watch Video
                  </Typography>
                </div>
              </Grid>
              <Grid item md={4} />
            </Grid>
          </div>
        </div>

        {/* OUR SERVICES */}

        <div
          id="services"
          class="newSection"
          style={{
            paddingTop: "100px",
            backgroundColor: "#f1f8fb",
          }}
        >
          <Grid container>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <div style={{ display: "flex", textAlign: "center" }}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Divider
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      className="divTitle"
                      variant="body"
                      sx={{ color: "primary.main", letterSpacing: "4px" }}
                    >
                      SERVICES
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={4} />
              </div>

              <Typography className="secTitle" sx={{ textAlign: "center" }}>
                Our Services
              </Typography>

              <Typography sx={{ color: "text.secondary", textAlign: "center" }}>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout. It
                is a long established fact that a reader.
              </Typography>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </div>

        {/* CARD */}

        <div
          style={{
            paddingLeft: "140px",
            paddingRight: "140px",
            backgroundColor: "#f1f8fb",
            paddingBottom: "70px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item md={12}>
              <div style={{ padding: "40px", marginTop: "2%" }}>
                <Grid container spacing={3}>
                  {param.servData.map((newServData) => {
                    return (
                      <Grid item md={4}>
                        <Card
                          sx={{
                            borderRadius: "20px",
                            "&:hover": {
                              color: "common.white",
                              backgroundColor: "#008cba",
                              transition: "0.5s ease",
                            },
                          }}
                        >
                          <CardContent
                            sx={{
                              textAlign: "center",
                              color: "primary.main",
                              "&:hover": {
                                color: "common.white",
                                transition: "0.6s ease",
                              },
                            }}
                          >
                            {newServData.icon}

                            <Typography
                              gutterBottom
                              fontFamily="Barlow Condensed"
                              fontWeight={600}
                              fontSize="4vh"
                              component="div"
                              textAlign="center"
                            >
                              {newServData.title}
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ padding: "20px" }}
                            >
                              {newServData.text}
                            </Typography>
                          </CardContent>
                          <div className="overlay"></div>
                        </Card>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        {/* STATS */}

        <div class="splitBG">
          <div class="stats">
            <div style={{ padding: "50px 100px 50px 100px" }}>
              <Grid container>
                {param.stats.map((newStats) => {
                  return (
                    <Grid item md={3} color="common.white">
                      {newStats.icon}
                      <ScrollTrigger
                        onEnter={() => setCounterOn(true)}
                        onExit={() => setCounterOn(false)}
                      >
                        <Typography
                          fontFamily="Barlow Condensed"
                          fontSize="5.5vh"
                          fontWeight="500"
                        >
                          {counterOn && (
                            <CountUp
                              start={0}
                              end={newStats.num}
                              duration={1}
                              delay={0}
                            />
                          )}
                          +{/* {newStats.num} */}
                        </Typography>
                      </ScrollTrigger>

                      <Typography>{newStats.title}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {/* ================================ EXPERIENCE ==================================*/}
      <div>
        <div style={{ paddingTop: "10%" }}>
          <div className="expBG">
            <div
              style={{
                marginLeft: "350px",
                marginRight: "350px",
                textAlign: "center",
                paddingTop: "220px",
                height: "700px",
              }}
            >
              {/* Clickable Icon */}
              <PlayCircleIcon
                sx={{
                  fontSize: "12vh",
                  color: "common.white",
                  "&:hover": {
                    cursor: "pointer",
                    color: "primary.main",
                  },
                }}
                // onClick={handleClickOpen}
              />

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
              >
                <DialogTitle>{"Use Google's location service?"}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    Let Google help apps determine location. This means sending
                    anonymous location data to Google, even when no apps are
                    running.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Disagree</Button>
                  <Button onClick={handleClose}>Agree</Button>
                </DialogActions>
              </Dialog>

              <Grid container>
                <Typography
                  color="common.white"
                  className="secTitle"
                  sx={{ pt: "20px", lineHeight: "1.3" }}
                >
                  We Have 30+ Years Experience With Utility Industry.
                </Typography>
                <Typography sx={{ color: "common.white", paddingTop: "20px" }}>
                  With a proud legacy spanning over 30 years, we have been at
                  the forefront of delivering unparalleled services and
                  solutions that power the future. Our seasoned professionals
                  bring a wealth of knowledge and hands-on experience, making us
                  a trusted partner in the dynamic landscape of utilities. Over
                  the decades, we have evolved with the industry, adapting to
                  technological advancements and industry trends to consistently
                  provide innovative and reliable solutions.
                </Typography>
              </Grid>
            </div>
          </div>
        </div>
      </div>

      {/* =============================== CLIENTS + ADS ===============================*/}
      <div>
        <div style={{}}>
          {/*CLIENTS' FEEDBACK */}
          <div
            class="newSection"
            style={{
              paddingTop: "100px",
            }}
          >
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <div style={{ display: "flex", textAlign: "center" }}>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Divider
                      className="divStyles"
                      sx={{
                        color: "primary.main",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        className="divTitle"
                        variant="body"
                        sx={{
                          letterSpacing: "4px",
                          color: "primary.main",
                        }}
                      >
                        TESTIMONIALS
                      </Typography>
                    </Divider>
                  </Grid>
                  <Grid item xs={4} />
                </div>

                <Typography className="secTitle" textAlign="center">
                  Clients' Feedback
                </Typography>

                <Typography textAlign="center" sx={{ color: "text.secondary" }}>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  It is a long established fact that a reader.
                </Typography>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </div>

          {/* CARD */}
          <div
            style={{
              paddingLeft: "140px",
              paddingRight: "140px",

              paddingBottom: "70px",
            }}
          >
            <Grid container spacing={1}>
              <Grid item md={12}>
                <div style={{ padding: "40px", marginTop: "2%" }}>
                  <Grid container spacing={3}>
                    {param.clientsData.map((newClientsData) => {
                      return (
                        <Grid item md={4}>
                          <Card
                            elevation={0}
                            sx={{
                              backgroundColor: "#f1f8fb",
                              color: "#008cba",
                              borderRadius: "15px",
                              "&:hover": {
                                // color: "#008cba",
                                transition: "0.5s ease",
                                // transform:
                              },
                            }}
                          >
                            <Typography
                              sx={{ paddingTop: "30px", textAlign: "center" }}
                            >
                              <FormatQuoteIcon sx={{ fontSize: "8vh" }} />
                            </Typography>

                            <CardContent
                              sx={{
                                textAlign: "center",
                                paddingLeft: "30px",
                                paddingRight: "30px",
                              }}
                            >
                              <Typography
                                gutterBottom
                                lineHeight={1.8}
                                fontWeight={600}
                                fontStyle="italic"
                                fontSize="2.3vh"
                                component="div"
                                textAlign="center"
                                sx={{ color: "text.secondary" }}
                              >
                                {newClientsData.msg}
                              </Typography>
                              <div
                                style={{
                                  // textAlign: "right",
                                  paddingTop: "15px",
                                  paddingBottom: "15px",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={2} />
                                  <Grid item xs={3}>
                                    <CardMedia
                                      textAlign="center"
                                      justifyContent="center"
                                      alignContent="center"
                                      alignItems="center"
                                      image={newClientsData.img}
                                      sx={{
                                        height: "12px",
                                        width: "12px",
                                        padding: "20px",
                                        borderRadius: "100%",
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={7}>
                                    <Typography
                                      fontFamily="Barlow Condensed"
                                      fontSize="2.7vh"
                                      color="common.black"
                                      fontWeight={600}
                                      // paddingLeft="20px"
                                      textAlign="left"
                                    >
                                      {newClientsData.name}
                                    </Typography>
                                    <Typography
                                      textAlign="left"
                                      // paddingLeft="20px"
                                    >
                                      {newClientsData.title}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </div>
                            </CardContent>
                            <div className="overlay"></div>
                          </Card>
                        </Grid>
                      );
                    })}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>

        {/* ADS */}
        <div>
          <div className="download">
            {/*CLIENTS' FEEDBACK */}
            <div
              class="newSection"
              style={{
                paddingTop: "100px",
              }}
            >
              <Grid container>
                <Grid item xs={3} />
                <Grid item xs={6}>
                  <div style={{ display: "flex", textAlign: "center" }}>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <Divider
                        color="common.white"
                        className="divStyles"
                        sx={{
                          color: "common.white",
                          textAlign: "center",
                        }}
                      >
                        <Typography
                          className="divTitle"
                          variant="body"
                          sx={{
                            letterSpacing: "4px",
                            color: "common.white",
                          }}
                        >
                          GET THE APP
                        </Typography>
                      </Divider>
                    </Grid>
                    <Grid item xs={4} />
                  </div>

                  <Typography
                    className="secTitle"
                    sx={{
                      textAlign: "center",
                      lineHeight: "1.3",
                      paddingTop: "30px",
                      color: "common.white",
                    }}
                  >
                    Download The App And Start <br />
                    To Promote Your Business.
                  </Typography>

                  <Typography
                    textAlign="center"
                    sx={{ color: "common.white", paddingTop: "25px" }}
                  >
                    Molestiae eveniet earum quo, expli cabo ut voluptates sunt a
                    illo aliqu ipsam orem ipsum dolor sit amet consectetur
                    adipisicing elit
                  </Typography>
                </Grid>
                <Grid item xs={3} />
              </Grid>

              <div
                style={{
                  textAlign: "center",
                  paddingTop: "50px",
                  paddingBottom: "184px",
                }}
              >
                <Grid container spacing="16">
                  <Grid item xs={4} />
                  <Grid item xs={2} textAlign="right">
                    <Button
                      sx={{
                        position: "static",
                        color: "common.white",
                        backgroundColor: "black",
                        "&:hover": {
                          color: "common.white",
                          backgroundColor: "#008cba",
                        },
                      }}
                      variant="contained"
                      startIcon={<ShopIcon />}
                      disableElevation
                    >
                      <div style={{ textAlign: "left" }}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            textTransform: "capitalize",
                          }}
                        >
                          Get it from
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                          }}
                        >
                          GOOGLE PLAY
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={2} textAlign="left">
                    <Button
                      sx={{
                        position: "static",
                        color: "primary.main",
                        backgroundColor: "common.white",
                        "&:hover": {
                          color: "common.white",
                          backgroundColor: "#008cba",
                        },
                      }}
                      variant="contained"
                      startIcon={<AppleIcon />}
                      disableElevation
                    >
                      <div style={{ textAlign: "left" }}>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            textTransform: "capitalize",
                          }}
                        >
                          Get it from
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                          }}
                        >
                          APPLE STORE
                        </Typography>
                      </div>
                    </Button>
                  </Grid>
                  <Grid item xs={4} />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ================================= CONTACT US ===============================*/}
      <div id="contact">
        {/* CONTACT US */}
        <div>
          <div
            class="newSection"
            style={{
              paddingTop: "100px",
            }}
          >
            <Grid container>
              <Grid item xs={3} />
              <Grid item xs={6}>
                <div style={{ display: "flex", textAlign: "center" }}>
                  <Grid item xs={4} />
                  <Grid item xs={4}>
                    <Divider
                      className="divStyles"
                      sx={{
                        color: "primary.main",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        className="divTitle"
                        variant="body"
                        sx={{
                          letterSpacing: "4px",
                          color: "primary.main",
                        }}
                      >
                        BE A PARTNER
                      </Typography>
                    </Divider>
                  </Grid>
                  <Grid item xs={4} />
                </div>

                <Typography className="secTitle" textAlign="center">
                  Contact Us
                </Typography>

                <Typography textAlign="center" sx={{ color: "text.secondary" }}>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  It is a long established fact that a reader.
                </Typography>
              </Grid>
              <Grid item xs={3} />
            </Grid>
          </div>

          {/* TEXTFIELDS */}

          <div style={{ paddingTop: "50px", paddingBottom: "120px" }}>
            <Grid container md={12} spacing={2}>
              <Grid item md={3} />
              <Grid item md={6}>
                <Grid container spacing={2}>
                  {/* First line */}
                  <Grid item md={6}>
                    <CssTextField
                      label="First Name"
                      variant="filled"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6}>
                    <CssTextField
                      label="Last Name"
                      variant="filled"
                      fullWidth
                    />
                  </Grid>

                  {/* Second line */}
                  <Grid item md={6}>
                    <CssTextField label="Email" variant="filled" fullWidth />
                  </Grid>
                  <Grid item md={6}>
                    <CssTextField label="Subject" variant="filled" fullWidth />
                  </Grid>

                  {/* Message */}
                  <Grid item md={12}>
                    <CssTextField
                      fullWidth
                      label="How Can We Help You?"
                      multiline
                      rows={4}
                      maxRows={6}
                      variant="filled"
                    />
                  </Grid>
                </Grid>
                {/* I Agree */}
                <div style={{ paddingTop: "30px" }}>
                  <Grid container>
                    <Grid item md={6}>
                      <FormControlLabel
                        control={<Checkbox />}
                        label="I Agree to the Terms & Conditions"
                        sx={{ color: "text.secondary" }}
                      />
                    </Grid>
                    <Grid item md={6} sx={{ textAlign: "right" }}>
                      <Button
                        elevation={0}
                        variant="contained"
                        sx={{
                          paddingLeft: "30px",
                          paddingRight: "30px",
                          "&:hover": {
                            backgroundColor: "#008cba",
                          },
                        }}
                      >
                        Send
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item md={3} />
            </Grid>
          </div>
        </div>

        {/* CONTACT DETAILS*/}
        <div class="contactSplitBG">
          {/* STATS */}
          <div>
            <div class="contactStrip">
              <div
                style={{
                  padding: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid container>
                  <Grid item lg={3} color="common.white">
                    <Stack direction="row" alignItems="center">
                      <CallIcon sx={{ padding: "20px", fontSize: 30 }} />
                      <Stack justifyContent="center" textAlign="left">
                        <Typography fontSize="1.8vh" fontWeight="400">
                          PHONE:
                        </Typography>
                        <Typography fontSize="2.7vh" fontWeight="600">
                          +63 123456789
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item lg={3} color="common.white">
                    <Stack direction="row" alignItems="center">
                      <EmailIcon sx={{ padding: "20px", fontSize: 30 }} />
                      <Stack justifyContent="center" textAlign="left">
                        <Typography fontSize="1.8vh" fontWeight="400">
                          EMAIL:
                        </Typography>
                        <Typography fontSize="2.7vh" fontWeight="600">
                          mail@gmail.com
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item lg={3} color="common.white">
                    <Stack direction="row" alignItems="center">
                      <LocationOnIcon sx={{ padding: "20px", fontSize: 30 }} />
                      <Stack justifyContent="center" textAlign="left">
                        <Typography fontSize="1.8vh" fontWeight="400">
                          LOCATION:
                        </Typography>
                        <Typography fontSize="2.7vh" fontWeight="600">
                          Batangas City
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item lg={3}>
                    <Button
                      sx={{
                        color: "primary.main",
                        backgroundColor: "common.white",
                        height: "60px",

                        width: "150px",
                        "&:hover": {
                          color: "common.white",
                          backgroundColor: "#008cba",
                        },
                      }}
                      variant="contained"
                      disableElevation
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontWeight: "600",
                        }}
                      >
                        Contact Us
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
