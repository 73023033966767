import React from "react";
import WelcomeInfo from "./components/welcome info/WelcomeInfo";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import "./App.css";

//Pages
import LandingPage from "./pages/landing page/LandingPage";
import ContactUs from "./pages/contact us/ContactUs";
import NavigationBar from "./components/nav bar/NavigationBar";

import UnderMaintenance from "./pages/underMaintenance/UnderMaintenance";
// index
import IndexPage from "./pages/index page/IndexPage";

// new navbar
import NavigationBarOnePage from "./components/nav bar/NavigationBarOnePage";
import AboutUs from "./pages/about us/AboutUs";
import Welcome from "./components/landingPage/Welcome";
import Teams from "./components/landingPage/Teams";

import TeamsV2 from "./components/landingPage/TeamsV2";
import Clients from "./components/landingPage/Clients";
import Footer from "./components/footer/Footer";

//Routing
import { HashRouter, Routes, Route } from "react-router-dom";
import Portfolio from "./components/landingPage/Portfolio";
import { Lan } from "@mui/icons-material";

const theme = createTheme({
  // typography: {
  //   fontFamily: "Barlow Condensed",
  //   fontWeightLight: 300,
  //   fontWeightRegular: 400,
  //   fontWeightMedium: 500,
  //   fontWeightBold: 600,
  // },

  palette: {
    primary: {
      main: "#013553",
      light: "#416B83",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
      contrastText: "#000",
    },
    divider: "#013553",
    component: {
      ".MuiFilledInput-root": {
        backgroundColor: "#f1f8fb",
        borderRadius: "5px",
      },
    },
  },

  // shape: {
  //   borderRadius: 15,
  // },
});

const App = () => {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <HashRouter>
          <UnderMaintenance />
          {/* <NavigationBarOnePage />
          <IndexPage />

          <Welcome />

          <Footer /> */}
        </HashRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
