import React from "react";

//imports
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid"; // Grid version 1
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";

//Icons for Contact Strip
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";

// import for checkbox
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

//import css
import "../../styles/landingPageStyles/contactStyles.css";
import { styled } from "@mui/material/styles";

const CssTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    backgroundColor: "#f1f8fb !important",
  },
  "& .Mui-focused": {
    backgroundColor: "#f1f8fb !important",
  },
  ".MuiFilledInput-multiline": {
    backgroundColor: "#f1f8fb !important",
  },
  "& .onhover": {
    backgroundColor: "#f1f8fb !important",
  },
});

const ContactDetails = () => {
  return (
    <div id="contact">
      {/* CONTACT US */}
      <div>
        <div
          class="newSection"
          style={{
            paddingTop: "100px",
          }}
        >
          <Grid container>
            <Grid item xs={3} />
            <Grid item xs={6}>
              <div style={{ display: "flex", textAlign: "center" }}>
                <Grid item xs={4} />
                <Grid item xs={4}>
                  <Divider
                    className="divStyles"
                    sx={{
                      color: "primary.main",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      className="divTitle"
                      variant="body"
                      sx={{
                        letterSpacing: "4px",
                        color: "primary.main",
                      }}
                    >
                      BE A PARTNER
                    </Typography>
                  </Divider>
                </Grid>
                <Grid item xs={4} />
              </div>

              <Typography className="secTitle" textAlign="center">
                Contact Us
              </Typography>

              <Typography textAlign="center" sx={{ color: "text.secondary" }}>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout. It
                is a long established fact that a reader.
              </Typography>
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </div>

        {/* TEXTFIELDS */}

        <div style={{ paddingTop: "50px", paddingBottom: "120px" }}>
          <Grid container md={12} spacing={2}>
            <Grid item md={3} />
            <Grid item md={6}>
              <Grid container spacing={2}>
                {/* First line */}
                <Grid item md={6}>
                  <CssTextField label="First Name" variant="filled" fullWidth />
                </Grid>
                <Grid item md={6}>
                  <CssTextField label="Last Name" variant="filled" fullWidth />
                </Grid>

                {/* Second line */}
                <Grid item md={6}>
                  <CssTextField label="Email" variant="filled" fullWidth />
                </Grid>
                <Grid item md={6}>
                  <CssTextField label="Subject" variant="filled" fullWidth />
                </Grid>

                {/* Message */}
                <Grid item md={12}>
                  <CssTextField
                    fullWidth
                    label="How Can We Help You?"
                    multiline
                    rows={4}
                    maxRows={6}
                    variant="filled"
                  />
                </Grid>
              </Grid>
              {/* I Agree */}
              <div style={{ paddingTop: "30px" }}>
                <Grid container>
                  <Grid item md={6}>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="I Agree to the Terms & Conditions"
                      sx={{ color: "text.secondary" }}
                    />
                  </Grid>
                  <Grid item md={6} sx={{ textAlign: "right" }}>
                    <Button
                      elevation={0}
                      variant="contained"
                      sx={{
                        paddingLeft: "30px",
                        paddingRight: "30px",
                        "&:hover": {
                          backgroundColor: "#008cba",
                        },
                      }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item md={3} />
          </Grid>
        </div>
      </div>

      {/* CONTACT DETAILS*/}
      <div class="contactSplitBG">
        {/* STATS */}
        <div>
          <div class="contactStrip">
            <div
              style={{
                padding: "20px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Grid container>
                <Grid item lg={3} color="common.white">
                  <Stack direction="row" alignItems="center">
                    <CallIcon sx={{ padding: "20px", fontSize: 30 }} />
                    <Stack justifyContent="center" textAlign="left">
                      <Typography fontSize="1.8vh" fontWeight="400">
                        PHONE:
                      </Typography>
                      <Typography fontSize="2.7vh" fontWeight="600">
                        +63 123456789
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item lg={3} color="common.white">
                  <Stack direction="row" alignItems="center">
                    <EmailIcon sx={{ padding: "20px", fontSize: 30 }} />
                    <Stack justifyContent="center" textAlign="left">
                      <Typography fontSize="1.8vh" fontWeight="400">
                        EMAIL:
                      </Typography>
                      <Typography fontSize="2.7vh" fontWeight="600">
                        mail@gmail.com
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item lg={3} color="common.white">
                  <Stack direction="row" alignItems="center">
                    <LocationOnIcon sx={{ padding: "20px", fontSize: 30 }} />
                    <Stack justifyContent="center" textAlign="left">
                      <Typography fontSize="1.8vh" fontWeight="400">
                        LOCATION:
                      </Typography>
                      <Typography fontSize="2.7vh" fontWeight="600">
                        Batangas City
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item lg={3}>
                  <Button
                    sx={{
                      color: "primary.main",
                      backgroundColor: "common.white",
                      height: "60px",

                      width: "150px",
                      "&:hover": {
                        color: "common.white",
                        backgroundColor: "#008cba",
                      },
                    }}
                    variant="contained"
                    disableElevation
                  >
                    <Typography
                      sx={{
                        fontSize: "15px",
                        fontWeight: "600",
                      }}
                    >
                      Contact Us
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
