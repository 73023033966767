import React from "react";

import under_maintenance from "../../assets/servmore_under_maintainance.png";

const UnderMaintenance = () => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "grid",
        placeItems: "center",
        overflow: "hidden",
      }}
    >
      <img
        src={under_maintenance}
        alt=""
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          maxWidth: "100%",
          objectPosition: "center",
        }}
      />
    </div>
  );
};

export default UnderMaintenance;
